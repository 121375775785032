<template>
  <div id="cont">
  <table id="editor">
    <tr>
      <td width="60%" class="toolbar" id="subtitler_toolbar">
        <button v-on:click="new_subtitle()" title="New Subtitle"><md-icon>add</md-icon></button>
        <button v-on:click="onPickSubFile()" title="Load Subtitle"><md-icon>file_upload</md-icon></button>
        <button v-on:click="save_subtitle(true)" title="Save Subtitle"><md-icon>file_download</md-icon></button>
        <input type="file" style="display: none" ref="subFileInput" accept=".srt" v-on:change="onSubFilePicked"/>
        &nbsp;&nbsp;
        <button title="Undo" v-on:click="perform_undo()" v-bind:disabled="previous_subtitles.length == 0"><md-icon>undo</md-icon></button>
        <button title="Redo" v-on:click="perform_redo()" v-bind:disabled="forward_subtitles.length == 0"><md-icon>redo</md-icon></button>
        &nbsp;&nbsp;
        <button v-on:click="insert_block_at_start()">Insert at start</button><button v-on:click="insert_block_at_end()">Insert at end</button>
      </td>
      <td width="40%"  class="toolbar" style="text-align: right" id="video_toolbar">
        <button v-on:click="onPickVidFile()" title="Load Video"><md-icon>movie</md-icon></button>
        <input type="file" style="display: none" ref="vidFileInput" accept=".mp4,.mpeg,.mp2" v-on:change="onVidFilePicked"/>
      </td>
    </tr>
    <tr>
      <td id="subtitle">
        <div id="blocks-container">
        <table>
          <tr>
            <td>#</td>
            <th scope="col">Start</th>
            <th scope="col">End</th>
            <th scope="col">Duration</th>
            <th scope="col">Text</th>
            <th scope="col">Actions</th>
          </tr>
          <tr v-on:click="selected_block = index" v-for="(block, index) in subtitle" :key="index" v-bind:id="'rowblock-'+index" v-bind:class="{ 'row-even': (index%2)==0, 'row-odd': (index%2)==1, 'row-selected': index == selected_block }">
            <td class="td-row clickable" v-on:click="jump_video(block.start_mil,index,'row')">{{ index+1 }}</td>
            <td class="td-row">
              <button v-on:click="adjust_block(block, -1, true)"> &lt;&lt; </button> <button v-on:click="adjust_block(block, -0.1, true)"> &lt; </button>
              <div v-bind:ref="'starttime-'+block.idx" class="timecode-edit" contenteditable="true" v-on:input="parse_timecode(block, true)">{{ get_hours_string(block.start_mil) }}:{{ get_minutes_string(block.start_mil) }}:{{ get_seconds_string(block.start_mil) }}</div>
              <button v-on:click="adjust_block(block, 0.1, true)"> &gt; </button><button v-on:click="adjust_block(block, 1, true)"> &gt;&gt; </button>
            <td class="td-row">
              <button v-on:click="adjust_block(block, -1, false)"> &lt;&lt; </button><button v-on:click="adjust_block(block, -0.1, false)"> &lt; </button>
              <div v-bind:ref="'endtime-'+block.idx" class="timecode-edit" contenteditable="true" v-on:input="parse_timecode(block, false)">{{ get_hours_string(block.end_mil) }}:{{ get_minutes_string(block.end_mil) }}:{{ get_seconds_string(block.end_mil) }}</div>
              <button v-on:click="adjust_block(block, 0.1, false)"> &gt; </button><button v-on:click="adjust_block(block, 1, false)"> &gt;&gt; </button>
            </td>
            <td class="td-row">{{ block.duration }}</td>
            <td class="td-row">
              <textarea v-on:input="push_and_preview()" class="blocktext" v-model="block.text"></textarea>
            </td>
            <td class="td-row">
              <button title="Delete" v-on:click="delete_block(block)"><md-icon>delete</md-icon></button> <!--<button v-on:click="insert_block_above(block)"><md-icon>vertical_align_top</md-icon></button> <button v-on:click="insert_block_below(block)"><md-icon>vertical_align_bottom</md-icon></button>-->
            </td>
          </tr>
        </table>
        </div>
      </td>
      <td id="video_container">
        <video v-show="video_url != ''" controls class="right" id="videoP" v-bind:src="video_url">
        </video>
      </td>
      </tr>
  </table>
  <div id="navigator-container" v-if="video_length > 0">
    <div id="video-navigator" v-bind:style="{width: navigator_length*zoom + 'px', 'background-size': (navfactor*2*zoom)+'px '+(navfactor*2*zoom)+'px'}">
      <div id="navline" v-bind:style="{left: (Math.floor((this.vid_current_time/video_length)*navigator_length)) + 'px'}"></div>
      <div v-for="(block, index) in subtitle" :key="index" v-bind:id="'navblock-'+index" v-on:click="selected_block = index; jump_video(block.start_mil,index,'nav')" v-bind:style="{ width: (Math.floor((block.duration/video_length)*navigator_length)) + 'px', left:  (Math.floor((block.start_mil/video_length)*navigator_length)) + 'px' }" v-bind:class="{ 'block-even': (index%2)==0, 'block-odd': (index%2)==1, 'block-selected': index == selected_block }">
      {{ block.idx }}<br />
      {{ block.text }}
      </div>
    </div>
  </div>
  </div>
</template>

<script>
var FileSaver = require('file-saver');

export default {
  name: 'Subtitler',
  props: {
    msg: String
  },
  data: () => ({
    subtitle: [{idx: 1, start:0, end:0, start_mil:0, end_mil:2, duration:2, text:""}],
    video_url: "",
    subtitle_name: "subtitle.srt",
    previous_subtitles: [],
    forward_subtitles: [],
    max_undo_steps: 100,
    cues: [],
    text_track: null,
    video: null,
    selected_block: 0,
    video_length: 0,
    navigator_length: 60000,
    navfactor: 40, // 50px per sec?
    default_block_length: 2,
    zoom: 1,
    vid_current_time: 0
  }),
  methods: {
    onPickSubFile: function() {
        this.$refs.subFileInput.click();
    },
    onSubFilePicked: function(event) {
        const files = event.target.files;
        //let filename = files[0].name;
        const fileReader2 = new FileReader();
        fileReader2.addEventListener('load', () => {
          this.load_subtitle(fileReader2.result);
         // this.saveConfig();
         
        });
        fileReader2.readAsText(files[0]);
    },
    onPickVidFile: function() {
        this.$refs.vidFileInput.click()
    },
    onVidFilePicked: function(event) {
        const files = event.target.files;
        //let filename = files[0].name;
        this.video_url = URL.createObjectURL(files[0]);
    },
    load_subtitle: function(subtitleData) {
      //console.log(subtitleData);
      this.subtitle = [];
      this.previous_subtitles = [];
      this.forward_subtitles = [];
      let subtitleData_pr = subtitleData.replaceAll("\r\n","\n");
      var lines = subtitleData_pr.split('\n');
      let isVTT = false;
      let block = this.generate_new_block(1,0,0);

      let starting = 1; // first line in valid srt would be the number 1
      let num_blocks = 1;
      if (lines[0].includes("WEBVTT")) {
        isVTT = true;
        console.log(isVTT);
        starting = 3; // second line should be empty
      }

      for(var i = starting; i < lines.length; i++){
        if (lines[i] === "") { // new block starting
          this.subtitle.push(block);
          num_blocks = num_blocks+1;
          block = this.generate_new_block(num_blocks,0,0);
          i++; // dangerous, skipping the next line which should be a num
        } else if (lines[i].includes(" --> ")) {
          let durations = lines[i].split(" --> ");
          block.start = durations[0];
          block.end = durations[1];
          block.start_mil = this.generate_in_seconds(block.start);
          block.end_mil = this.generate_in_seconds(block.end);
          block.duration = Math.floor((block.end_mil - block.start_mil)*1000)/1000;
        } else {
          if (block.text !== "") block.text += "\n";
          block.text += lines[i];
        }
      }
      this.preview_subtitles();
      console.log(this.subtitle);
    },
    new_subtitle: function() {
      this.subtitle = [{idx: 1, start:0, end:0, start_mil:0, end_mil:this.default_block_length, duration:this.default_block_length, text:""}];
      this.previous_subtitles = [];
      this.forward_subtitles = [];
      this.preview_subtitles();
    },
    save_subtitle: function(isVTT) {
      let lines = this.generate_subtitle_string(isVTT);

      var blob = new Blob([lines], {type: "text/plain;charset=utf-8"});
      FileSaver.saveAs(blob, "subtitle.srt");
    },
    parse_timecode: function(block, isStart) {
      try {
        console.log(this.$refs["endtime-"+block.idx][0]);
        let tentative_val = 0;
        if (isStart)  tentative_val = this.generate_in_seconds(this.$refs["starttime-"+block.idx][0].innerText);
        else tentative_val = this.generate_in_seconds(this.$refs["endtime-"+block.idx][0].innerText);
        console.log(tentative_val);
        if (!isNaN(tentative_val)) {
          if (isStart)  block.start_mil = tentative_val;
          else block.end_mil = tentative_val;      
          this.update_duration(block);
        } else {
          // revert
          if (isStart)  this.$refs["starttime-"+block.idx][0].innerText = this.generate_time_code(block.start_mil, false);
          else this.$refs["endtime-"+block.idx][0].innerText = this.generate_time_code(block.end_mil, false);    
          return;
        }

        this.push_undo();
        this.preview_subtitles();
      } catch (error) {
        console.log(error);
      }
    },
    generate_in_seconds: function(frame_string) {
      let milsecs = frame_string.split(","); // milliseconds in [1]
      let colons = milsecs[0].split(":"); // [0] - h [1] m - [2] s
      let milliseconds = parseInt(milsecs[1])/1000;
      let secs = 0.0 + 3600 * parseInt(colons[0]) + 60*parseInt(colons[1]) + parseInt(colons[2]) + milliseconds;

      return secs;
    },

    get_hours_string: function(secs) {
      let hours = this.get_hours(secs);
      if (hours < 10) hours = "0"+hours;
      else hours = ""+hours;
      return hours;
    },
    get_hours: function(secs) {
      return Math.floor(secs/3600);
    },
    get_minutes_string: function(secs) {
      let minutes = this.get_minutes(secs);
      if (minutes < 10) minutes = "0"+minutes;
      else minutes = ""+minutes;
      return minutes;
    },
    get_minutes: function(secs) {
      let hours_in_minutes = this.get_hours(secs)*60;
      let minutes = Math.floor((secs-hours_in_minutes)/60);
      return minutes;
    },
    get_seconds_string: function(secs, isVTT) {
      let seconds = this.get_seconds(secs);
      let padding = "";
      if (seconds < 10) padding = "0";
      let second_string = (padding + seconds.toFixed(3));
      if (!isVTT) second_string = second_string.replace(".",",");
      return second_string;
    },
    get_seconds: function(secs) {
      let minutes_in_seconds = this.get_minutes(secs)*60;
      let seconds = secs - minutes_in_seconds;
      return seconds;
    },
     generate_time_code: function(time, isVTT) {
      let time_code = this.get_hours_string(time) +":"+this.get_minutes_string(time) + ":" + this.get_seconds_string(time, isVTT);
      return time_code;
    },
    generate_new_block: function(idx,start,length) {
      let block = {
        idx: idx,
        start: start,
        end: start+length,
        start_mil: start,
        end_mil: start+length,
        duration: length,
        text: ""
      };
      return block;
    },

    generate_subtitle_string: function(isVTT) {
      let lines = "";
      if (isVTT) {
        lines += "WEBVTT\n\n";
      }
      for (let block of this.subtitle) {
        lines += block.idx +"\n";
        lines += this.generate_time_code(block.start_mil, isVTT);
        lines += " --> "
        lines += this.generate_time_code(block.end_mil, isVTT) + "\n";
        lines += block.text + "\n";
        lines += "\n";
      }
      return lines;
    },
    insert_block_at_start: function() {
      this.push_undo();
      let new_block = this.generate_new_block(1,0,this.default_block_length);
      let subtitles_new = [];
      subtitles_new.push(new_block);
      for (let block of this.subtitle) {
        block.idx +=1;
        subtitles_new.push(block);
      }
      this.subtitle = subtitles_new;
      this.preview_subtitles();
    },
    insert_block_at_end: function() {
      this.push_undo();
      let block = this.generate_new_block(this.subtitle.length+1,this.get_max_end()+0.2,this.default_block_length);
      this.subtitle.push(block);
      this.preview_subtitles();
    },
    delete_block:function(block) {
      this.push_undo();
      let idx_break = block.idx;
      this.subtitle.splice(idx_break-1,1); // idx starts 1, and array at 1
      for (var b = idx_break-1; b < this.subtitle.length; b++) {
        this.subtitle[b].idx = b+1; // subtract one on insertion
      }
      console.log(this.subtitle);
      this.preview_subtitles();
    },
    get_max_end: function() {
      let max = 0.0;
      for (let block of this.subtitle) {
        if (block.end_mil > max) max = block.end_mil;
      }
      return max;
    },
    adjust_block: function(block, increment,isStart) {
      this.push_undo();
      if (isStart) block.start_mil += increment;
      else block.end_mil += increment;
      this.update_duration(block);
      this.preview_subtitles();
    },
    update_duration: function(block) {
      block.duration = Math.floor((block.end_mil - block.start_mil)*1000)/1000;
    },
    push_and_preview: function() {
      this.push_undo();
      this.preview_subtitles();
    },
    push_undo: function() {
      let subtitle_copy = JSON.parse(JSON.stringify(this.subtitle));
      this.previous_subtitles.push(subtitle_copy);
      this.forward_subtitles = []; // empty on new undo
      if (this.previous_subtitles.length > this.max_undo_steps) this.previous_subtitles.splice(0,1); // pop the first element
    },
    perform_undo: function() {
      if (this.previous_subtitles.length == 0) return;
      let subtitle_copy = JSON.parse(JSON.stringify(this.subtitle));
      this.forward_subtitles.push(subtitle_copy);
      this.subtitle = this.previous_subtitles[this.previous_subtitles.length-1];
      this.previous_subtitles.splice(this.previous_subtitles.length-1, 1);
      console.log(this.subtitle);
      this.preview_subtitles();
    },
    perform_redo: function() {
      if (this.forward_subtitles.length == 0) return;
      let subtitle_copy = JSON.parse(JSON.stringify(this.subtitle));
      this.previous_subtitles.push(subtitle_copy);
      this.subtitle = this.forward_subtitles[this.forward_subtitles.length-1];
      this.forward_subtitles.splice(this.forward_subtitles.length-1, 1);

      this.preview_subtitles();
    },

    preview_subtitles: function() {
      for (let cue of this.cues) {
        this.text_track.removeCue(cue);
      }
      this.cues = [];

      for (let block of this.subtitle) {
        let ncue = new VTTCue(block.start_mil,block.end_mil,block.text);
        this.cues.push(ncue);
        this.text_track.addCue(ncue);
      }
    },
    jump_video: function(sec, index, source) {
      this.video.currentTime = sec;
      if (source == 'nav') document.getElementById("rowblock-"+index).scrollIntoView();
      else if (source == 'row') document.getElementById("navblock-"+index).scrollIntoView();
    }

  },
  mounted: function() {
        this.video = document.getElementById('videoP');
        this.text_track = this.video.addTextTrack('subtitles', 'English', 'en');
        this.text_track.mode = "showing";

        let self = this;
        this.video.addEventListener('loadeddata', function() {
          self.video_length = self.video.duration;
          self.navigator_length = Math.ceil(self.navfactor*self.video_length)*self.zoom; // round up
       }, false);

       this.video.ontimeupdate = function() {
         self.vid_current_time = self.video.currentTime;
         document.getElementById("navline").scrollIntoView();
       };
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

table {
  width: 100%;
   border-collapse: collapse;
}

td {
  text-align: left;
  vertical-align: top;
  margin: 0;

}

.td-row {
  vertical-align: middle;
  padding: 3px;
}

.toolbar {
  padding-bottom: 15px;

}

.blocktext {
  width: 350px;
  height: 50px;
  resize: none;
}

#videoP {
  max-width: 100%;
}

#blocks-container {
  overflow: auto;
  overflow-x: hidden;
  height: 650px;
  max-height: 100%;
}

.row-even {
  background: white;
}
.row-odd {
  background: #ddd;
}

.row-selected {
  border: 2px solid black;
}


#navigator-container {
  max-width: 100%;
  border: 1px solid black;
  overflow-y: auto;
  margin-top: 10px;
  background-image:linear-gradient(#434343, #282828);
}

#video-navigator {
  height: 130px;
  position: relative;
  background-color: transparent;
  background-image:   linear-gradient(0deg, transparent 24%, 
                      rgba(255, 255, 255, .05) 25%, 
                      rgba(255, 255, 255, .05) 26%,
                      transparent 27%,
                      transparent 74%, 
                      rgba(255, 255, 255, .05) 75%,
                       rgba(255, 255, 255, .05) 76%, 
                      transparent 77%,
                      transparent),
                      linear-gradient(90deg, 
                      transparent 0%, rgba(255, 255, 255, .05) 0%, 
                      rgba(255, 255, 255, .05) 1%, 
                      transparent 2%, transparent 49%, 
                      rgba(255, 255, 255, .05) 50%, rgba(255, 255, 255, .05) 51%,
                       transparent 52%, transparent);
  background-size:80px 80px;
}

.block-even {
  background: #3f3;
  position: absolute;
  top: 5px;
  height: 50px;
  overflow: hidden;
  font-size: 10px;
}
.block-odd {
  background: #f33;
  position: absolute;
  bottom: 10px;
  height: 50px;
  overflow: hidden;
  font-size: 10px;
}

#navline {
  border-left: 2px solid white;
  height: 130px;
  position: absolute;
  width:20px;
}

.block-selected {
  border: 2px solid white;
}

.clickable {
  cursor: pointer;
}

.timecode-edit {

}

.right {
    margin-left: auto;
    margin-right: 0;
    display: block
}


</style>
